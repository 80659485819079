.footer {
    position: relative;
    overflow: clip;
    display: grid;
    grid-template:
    "title title" max-content
    "message contact" max-content
    "credits credits" max-content
    / 1fr 1fr;

    gap: 16px;
    padding: 72px var(--spacing--margin) 24px;
}

.footer__title {
    grid-area: title;

    letter-spacing: 0.13em;
}

.footer__message {
    grid-area: message;

    font-weight: 500;
    font-size: 14px;
}

.footer__contact {
    grid-area: contact;
    justify-self: end;

    font-size: 16px;

    a {
      text-decoration: none;
      color: var(--vanilla-chocolate);
    }
}


.footer__credits {
    grid-area: credits;
    justify-self: center;

    font-size: 12px;
}

@media only screen and (max-width: 544px) {
    .footer {
        grid-template:
        "title" max-content
        "message" max-content
        "contact" max-content
        "credits" max-content
        / 1fr;

        text-align: center;
    }

    .footer__contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;

    }
}
