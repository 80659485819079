.caseHeader {
    display: flex;
    flex-direction: column;
    gap: 88px;
    height: 60vw;
    width: 100%;
    margin-top: -74px;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.intuit {
        align-items: flex-end;
        justify-content: flex-end;
        background-image: url('../../public/intuit/headerimageintuit.png');
    }

    &.tpc {
        align-items: flex-start;
        justify-content: flex-end;
        background-image: url('../../public/tpc/tpcheader.png');
    }

    &.lcbo {
        align-items: flex-start;
        justify-content: center;
        background-image: url('../../public/lcbo/lcboheader.png');
    }
}

@media only screen and (max-width: 708px) {
    .caseHeader {
        &.intuit {
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            background-image: none;
        }
        &.tpc {
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            background-image: none;
        }
        &.lcbo {
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            background-image: none;
        }
        .div {
            word-wrap: break-word;
            width: 100%;
        }
    }
}


.caseHMW {
    padding-top: 88px;
    padding-bottom: 88px;
    flex-direction: column;
    gap: 88px;
    text-align: center;
    width: 100%;

    background-color: var(--case-theme);
    color: var(--just-white);
}

.caseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 88px;

    h1 { margin-top: 0px; }
}


.caseContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 89px;
    text-align: left;
    width: calc(100% - 2 * var(--case-margin));

    &.buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 56px;
    }

    .buttonBar {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 24px;
        width: 100%;
    }
}

.caseImage {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
        width: calc(100% - 2 * var(--case-margin));
        border: 1px var(--case-theme) solid;
        border-radius: 16px;
        overflow: clip;
    }

    &.full img {
        border: none;
        width: 100%;
    }

    &.hideBorder {
        img, video {
            border: none;
        }
    }
}


.caseIntro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 89px;
    text-align: left;
    width: calc(100% - 2 * var(--case-margin));
}

.caseContext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    white-space: nowrap;
}

@media only screen and (max-width: 1096px) {
    .caseIntro {
        flex-direction: column;
        gap: 0px;
    }

    .caseContext {
        flex-direction: row;
        justify-content: space-between;
        white-space: normal;
        gap: 16px;
    }
}

@media only screen and (max-width: 544px) {
    .caseIntro, .caseContent {
        flex-direction: column;
        gap: 0px;
    }
}

/* BUTTON STYLES */
.button {
    border-radius: 30px;
    padding: 15px;
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: var(--just-white);
    cursor: pointer;

    &:hover {
      filter: brightness(.85);
    }

    &:active {
      filter: brightness(.75);
    }
}


.lightTheme .button {
    &:hover {
        filter: brightness(1.1);
    }

    &:active {
        filter: brightness(1.25);
    }
}
