/* COLORS */
body {
    --light-mode-bg: #FFFAFB;
    --dark-mode-bg: #202020;
    --masato-blue: #5656FF;
    --little-bro-blue: #6262FF;
    --syo-pink: #FF6EA2;
    --brownie-brown: #533030;
    --hot-chocolate-brown: #533030;
    --just-white: #FFFFFF;
    --salt-and-pepper: #D7D7D7;
    --light-intuit-blue: #74A2FF;
    --intuit-blue: #236CFF;
    --light-tpc-teal: #06C0D9;
    --tpc-teal: #007E8F;
    --light-lcbo-green: #7AE1C7;
    --lcbo-green: #173F35;
    --dull-lcbo-green: #40947F;

    --color-background: var(--dark-mode-bg);
    --blueberry-swirl: var(--just-white);
    --acai-bowl: var(--salt-and-pepper);
    --strawberry: var(--syo-pink);
    --vanilla-chocolate: var(--just-white);
    --marshallow-cinnamon: var(var(--salt-and-pepper));
    --vanilla-strawberry: var(--just-white);

    --intuit-primary: var(--light-intuit-blue);
    --tpc-primary: var(--light-tpc-teal);
    --lcbo-primary: var(--light-lcbo-green);

    --intuit-button: var(--intuit-blue);
    --tpc-button: var(--tpc-teal);
    --lcbo-button: var(--dull-lcbo-green);

    color: var(--blueberry-swirl);
    background: var(--color-background);

    .color--blueberry-swirl {
        color: var(--blueberry-swirl);
    }
    .color--acai-bowl {
        color: var(--acai-bowl);
    }
    .color--strawberry {
        color: var(--strawberry);
    }
    .color--vanilla-chocolate {
        color: var(--vanilla-chocolate);
    }
    .color--marshallow-cinnamon {
        color: var(--marshallow-cinnamon);
    }

    .color--intuit {
        color: var(--intuit-primary);
    }
    .color--tpc {
        color: var(--tpc-primary);
    }
    .color--lcbo {
        color: var(--lcbo-primary);
    }

    /* Spacing */
    --spacing--margin: 120px;
    --page-banner-height: 576px;
    --case-margin: 20vw;
    --nav-bar-height: 72px;

    /* Animation */
    --theme-transition-duration: 0.25s;
    transition-property: color, background;
    transition-duration: var(--theme-transition-duration);
}

body.lightTheme {
    --color-background: var(--light-mode-bg);
    --blueberry-swirl: var(--masato-blue);
    --acai-bowl: var(--little-bro-blue);
    --vanilla-chocolate: var(--brownie-brown);
    --marshallow-cinnamon: var(--hot-chocolate-brown);
    --vanilla-strawberry: var(--syo-pink);
    
    --intuit-primary: var(--intuit-blue);
    --tpc-primary: var(--tpc-teal);
    --lcbo-primary: var(--lcbo-green);

    --intuit-button: var(--intuit-blue);
    --tpc-button: var(--tpc-teal);
    --lcbo-button: var(--lcbo-green);
}

/* FONTS */
* {
    font-family: "DM Sans"; 
}

h1, .h1 {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.3;

    i {
        font-family: 'Crimson Pro';
        font-size: 44px;
        font-weight: 300;
    }
}

h1.h1--display, .h1--display {
    font-size: 50px;
    font-weight: 400;
    line-height: 1.4;

    i {
        font-size: 54px;
        font-weight: 600;
    }
}

h2, .h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.3;

    &.bold {
        font-weight: 700;
    }
}

h3, .h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;

    i {
        font-family: 'Crimson Pro';
    }
    
    b.bold {
        font-weight: 700;
    }
}

h3.h3--medium, .h3--medium {
    font-weight: 500;
}

h3.h3--subheading, .h3--subheading {
    font-size: 24px;
    font-weight: 500;
    font-style: italic;
    --strawberry: var(--syo-pink);
}

p, .p {
    font-family: "Inter"; 
    font-size: 18px;
    line-height: 1.5;
    color: var(--vanilla-chocolate)
}


@media only screen and (max-width: 1096px) {
    body {
        --spacing--margin: 64px;
    }
}

@media only screen and (max-width: 708px) {
    body {
        --spacing--margin: 24px;
        --case-margin: 5vw;
        --nav-bar-height: 83px;
    }
    p, .p {
        font-size: 15px;
    }
    h3.h3--subheading, .h3--subheading {
        font-size: 15px;
    }
    h3.h3--medium, .h3--medium {
        font-size: 15px;
    }
    h3, .h3 { 
        font-size: 15px;
    }
    h2, .h2 {
        font-size: 22px;
    }
    h1.h1--display, .h1--display {
        font-size: 22px;

        i {
            font-size: 26px;
        }
    }
    h1, .h1 {
        font-size: 22px;

        i {
            font-size: 26px;
        }
    }
}   