.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  min-height: calc(100vh - var(--nav-bar-height));
  margin-top: var(--nav-bar-height);
}

.page {
  margin-top: var(--nav-bar-height);
}

@media (prefers-reduced-motion: no-preference) {
  .app__logo {
    animation: app__logo__spin infinite 20s linear;
  }
}

@keyframes app__logo__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
