.diarypage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.diarypage__banner {
    position: relative;
    width: 100%;
    min-height: var(--page-banner-height);
}

.diarypage__banner__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 64px;
    padding: 24px var(--spacing--margin);

    --banner-content-width: 536px;

    img { 
        width: calc(100% - var(--banner-content-width) - 64px);
        max-width: max-content;
        min-width: 255px;
    }

    .diarypage__banner__content {
        width: var(--banner-content-width);
        text-align: left;

        h1 {
            text-shadow: 2px 4px 30px rgba(255, 255, 255, 0.4);
        }
        
        h3 {
            line-height: 1.5;
        }
    }
}

.diarypage__divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 64px var(--spacing--margin) 128px var(--spacing--margin);
    text-align: center;
}

.diarypage__resume {
    display: grid;
    grid-template-columns: max-content minmax(224px, 1fr) 2fr;
    gap: 64px;
    width: calc(100% - 2 * var(--spacing--margin));
    margin-bottom: 144px;

    .diarypage__resume__entry {
        h3 {
            margin-top: 0;
            line-height: 1.65;
            margin-bottom: 8px;
        }
    }
    
    .diarypage__resume__description {
        h3 {
            margin-top: 0;
            margin-bottom: 8px;
            line-height: 1.5;
        }
    }
}

@media only screen and (max-width: 1096px) {
    .diarypage__resume {
        grid-template-columns: minmax(224px, 1fr) 2fr;
        gap: 24px;

        .h1 {
            margin-block: 36px 24px;
        }

        
        .diarypage__resume__entry {
            grid-column: 1;

            h3 {
                line-height: 1.5;
            }
        }

        .diarypage__resume__description {
            grid-column: 2;
        }
    }
}

@media only screen and (max-width: 544px) {
    .diarypage__resume {
        grid-template-columns: 1fr;
        gap: 0px;

        .diarypage__resume__description {
            grid-column: 1;
            margin-bottom: 24px;
        }
        
        h3 {
            font-size: 18px;
        }
    }
}
