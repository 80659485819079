.toggle {
    --toggle-height: 32px;
    --toggle-width: 64px;
    --icon-size: 24px;
    --icon-gap: calc(var(--toggle-height) - var(--icon-size));
    width: var(--toggle-width);
    height: var(--toggle-height);
    border-radius: var(--toggle-height);
    
    position: relative;
    background: #949090;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1);

    .icon {
        width: var(--icon-size);
        height: var(--icon-size);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        
        left: var(--icon-gap);
        transition-duration: calc(var(--theme-transition-duration) / 2);
    }

    .heart {
        opacity: 0;
        transition-property: opacity, left;
    }

    .star {
        opacity: 1;
        transition-property: opacity, left;
    }
}

.lightTheme {
    .toggle {
        background: #FAE1E9;

        .icon {
            left: calc(var(--toggle-width) - var(--icon-gap) - var(--icon-size));
        }

        .heart {
            opacity: 1;
        }

        .star {
            opacity: 0;
        }
    }
}

.long {
    --long-line-length: 8px;
    --long-line-width: 4px;

    position: absolute;
    border-radius: var(--long-line-width);
    background-color: var(--vanilla-strawberry);
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-delay: var(--theme-transition-duration);

    &.vertical {
        width: var(--long-line-width);
    }

    &.horizontal {
        height: var(--long-line-width);
        bottom: 0;
        top: 0;
        margin-block: auto;
    }
}

@keyframes lineleft {
    0% {
        left: 0px;
        width: 0px;
    }
    50% {
        left: calc(-1 * var(--long-line-length));
        width: var(--long-line-length);
    }
    100% {
        left: calc(-1 * var(--long-line-length));
        width: 0px;
    }
}

@keyframes lineright {
    0% {
        left: calc(var(--toggle-width));
        width: 0px;
    }
    50% {
        left: calc(var(--toggle-width));
        width: var(--long-line-length);
    }
    100% {
        left: calc(var(--long-line-length) + var(--toggle-width));
        width: 0px;
    }
}

@keyframes lineup {
    0% {
        top: 0;
        height: 0px;
    }
    50% {
        top: calc(-1 * var(--long-line-length));
        height: var(--long-line-length);
    }
    100% {
        top: calc(-1 * var(--long-line-length));
        height: 0px;
    }
}

@keyframes linedown {
    0% {
        top: 0px;
        height: 0px;
    }
    50% {
        top: 0px;
        height: var(--long-line-length);
    }
    100% {
        top:  var(--long-line-length);
        height: 0px;
    }
}
