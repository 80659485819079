.highlightcard {
    width: 100%;
    height: 1200px;

    display: grid;
    grid-template-columns: repeat(2, minmax(576px, 1fr));
    align-items: center;
    justify-items: center;

    a {
        text-decoration: none;
        color: var(--blueberry-swirl);
    }

    a:hover {
        text-decoration: none;
        color: var(--acai-bowl)
    }
}

.highlightcard__content {
    width: 456px;
    padding-left: var(--spacing--margin);
    padding-right: 0;
}

.highlightcard.right  .highlightcard__content {
    padding-left: 0;
    padding-right: var(--spacing--margin);
}

.highlightcard__image {
    position: relative;
    display: flex;
    justify-content: center;
}

.highlightcard__image > img {
    will-change: transform;
}

.highlightcard__content a:hover {
    svg {
        animation: arrowPulseRight .5s infinite alternate ease-in-out;
    }
}

@keyframes arrowPulseRight {
    from {
        transform: translateX(0);
    }
    
    to {
        transform: translateX(25%);
    }
}

@media only screen and (max-width: 1096px) {
    .highlightcard {
        grid-template-columns: 1fr minmax(64px, 1fr);
        align-items: center;
        justify-items: center;
        gap: 24px;
    }
    
    .highlightcard.right {
        grid-template-columns: minmax(64px, 1fr) 1fr;
    }

    .highlightcard__content {
        width: auto;
    }
}

@media only screen and (max-width: 708px) {
    .highlightcard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        height: auto;
        gap: 24px;
    }

    .highlightcard.right {
        flex-direction: column-reverse;
    }
    

    .highlightcard__content, 
    .highlightcard.right .highlightcard__content {
        padding: 0px var(--spacing--margin);
    }

    .highlightcard__image {
        padding-block: 96px;
    }
    
    .highlightcard__content {
        svg {
            height: 16px;
        }
    }
}
