.morePage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.morePage__banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--page-banner-height);
    width: 100%;
    text-align: center;

    h1 {
        margin-top: 80px;
        text-shadow: 2px 4px 30px rgba(255, 255, 255, 0.4);
    }

    > h1, > p {
        padding-inline: var(--spacing--margin)
    }
}

.lightTheme {
    .morePage__banner {
        h1 {
            text-shadow: 2px 4px 30px rgba(86, 86, 255, 0.4);
        }
    }
}

.morePage__footer {
    text-align: center;
    margin-inline: var(--spacing--margin);
}

.morePage__fcGraphic {
    width: 100%;
    margin-block: 32px;
}

.morePage__starterhacks {
    display: flex;
    flex-direction: row;
    margin: 32px var(--spacing--margin);
    gap: 32px;
}

.morePage__starterhacks__graphic {
    margin-block: 32px;
    width: 576px;
}

.morePage__gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 60px;

    width: calc(100% - 2 * var(--spacing--margin));
}

@media only screen and (max-width: 1096px) {
    .morePage__starterhacks {
        flex-wrap: wrap;
    }

    .morePage__starterhacks__graphic {
        width: 100%;
    }
}
