
.projectSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-block: 32px;
}

.projectSummary__title {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-inline: var(--spacing--margin);
}

.projectSummary__description {
    max-width: 544px;
    margin-inline: var(--spacing--margin);
}

@media only screen and (max-width: 1096px) {
    .projectSummary__description {
        max-width: none;
    }
}
