.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--spacing--margin);
    height: var(--nav-bar-height);

    position: fixed;
    z-index: 1;
    top: 0;
    background: var(--color-background);
    width: calc(100% - 2 * var(--spacing--margin));
}

.navbar__logo {
    color: #FF4C8C;
    text-decoration: none;
}

.navbar__home, .navbar__tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.navbar__tabs__tab {
    color: var(--vanilla-chocolate);
    text-decoration: none;
    white-space: nowrap;
    font-size: 16px;
    padding-block: 8px;

    display: flex;
    flex-direction: column;
}

.navbar__tabs__tab--selected {
    font-family: 'Crimson Pro';
    font-weight: 700;
    font-style: italic;
    font-size: 20px;
}


@media only screen and (max-width: 708px) {
    .navbar {
        flex-direction: column;
        padding-block: 16px;
        gap: 8px;
    }

    navbar__home
    .navbar__logo {
        display: none;
    }

    .navbar__tabs {
        width: 100%;
        justify-content: space-evenly;
    }
}
