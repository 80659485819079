.homepage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage__banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--page-banner-height);
    width: 100%;
    text-align: center;
    
    > h1, > h3 {
        padding-inline: var(--spacing--margin)
    }
}

.homepage__banner__footer {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    gap: 32px;

    text-align: center;
}

@media only screen and (max-width: 1125px) {
    .homepage__banner__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
}

.homepage__divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 64px;

    svg {
        animation: arrowPulseDown .5s infinite alternate ease-in-out;
    }
}

@keyframes arrowPulseDown {
    from {
        transform: rotate(90deg) translateX(0);
    }
    
    to {
        transform: rotate(90deg) translateX(25%);
    }
}

.highlightcard__image {
    img#tpc {
        margin-left: -25%;
    }

    img#mom {
        margin-left: -15%;
    }
}

@media only screen and (max-width: 1096px) {
    .highlightcard__image {
        img#intuit {
            margin-left: 15%;
        }

        img#tpc {
            margin-left: -32%;
        }

        img#mom {
            margin-left: -25%;
        }
    }
}

@media only screen and (max-width: 708px) {
    .highlightcard__image {
        img#intuit {
            margin-left: auto;
        }
    }

    
    .homepage__divider {
        svg {
            height: 16px;
        }
    }
}